<template>
  <v-container fluid grid-list-xs pa-0>
    <v-layout row wrap>
      <v-flex xs12 md12 lg10 xl8>
        <v-card class="elevation-12">
          <v-progress-linear style="position: absolute;" v-show="loading" :indeterminate="true" class="ma-0"></v-progress-linear>
          <v-toolbar :color="!sessionDetailed ? 'secondary' : sessionDetailed.sessionStatusColor" dark tabs dense>
            <v-toolbar-title>Session notes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-chip outline v-if="sessionDetailed" small disabled color="white" text-color="white">
              <v-icon left>fa-info-circle</v-icon>
              {{ sessionDetailed.sessionStatus }}
            </v-chip>
            <v-menu :disabled="loading" left bottom transition="slide-y-transition">
              <v-btn slot="activator" icon :disabled="loading">
                <v-icon small color="white">fa-ellipsis-v</v-icon>
              </v-btn>
              <v-list>
                <v-list-tile @click="editTime" v-if="!editDisabled">
                  <v-list-tile-action>
                    <v-icon medium>fa-clock</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Edit time</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider v-if="!editDisabled"></v-divider>
                <v-list-tile to="/session/session_print">
                  <v-list-tile-action>
                    <v-icon medium>fa-print</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Print</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile v-if="!editDisabled && (!sessionDetailed || !sessionDetailed.sign) && !isBilled" @click="send2Email">
                  <v-list-tile-action>
                    <v-icon medium>fa-signature</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Send sign form to caregiver</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile v-else-if="!isBilled" @click="deleteSign">
                  <v-list-tile-action>
                    <span class="fa-stack">
                      <v-icon medium>fa-signature fa-stack-2x</v-icon>
                      <v-icon medium color="red lighten-3">fa-times fa-stack-1x</v-icon>
                    </span>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Delete sign</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile v-if="isAdminOrLead && (!sessionDetailed || sessionDetailed.sessionStatusCode !== 5) && !isBilled" @click="markAsChecked">
                  <v-list-tile-action>
                    <v-icon medium>fa-check-circle</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Mark as Checked</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <template v-if="!editDisabled && isAdminOrLead && (!sessionDetailed || sessionDetailed.sessionStatusCode !== 2)">
                  <v-divider></v-divider>
                  <v-list-tile @click="rejectSession">
                    <v-list-tile-action>
                      <v-icon medium>fa-exclamation-circle</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>Reject session</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </template>
                <template v-if="!editDisabled && isAdminOrLead && (!sessionDetailed || sessionDetailed.sessionStatusCode !== 5)">
                  <v-divider></v-divider>
                  <v-list-tile @click="deleteSession">
                    <v-list-tile-action>
                      <v-icon medium>fa-trash</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>Delete session</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </template>
                <template v-if="isAdminOrLead && (!sessionDetailed || sessionDetailed.sessionStatusCode === 5)">
                  <v-divider></v-divider>
                  <v-list-tile @click="reopenSession">
                    <v-list-tile-action>
                      <v-icon medium>fa-lock-open</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>Reopen session</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </template>
                <v-list-tile @click="goToData">
                  <v-list-tile-action>
                    <v-icon medium>fa-chart-line</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Data collection</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-menu>

            <v-tabs slot="extension" :color="!sessionDetailed ? 'secondary' : sessionDetailed.sessionStatusColor" dark show-arrows v-model="tabModel">
              <v-tab key="details">Details</v-tab>
              <v-tab v-if="session.sessionType == 1 || session.sessionType == 2" key="caregiver">Careg & Staff</v-tab>
              <template v-else-if="session.sessionType === 2">
                <v-tab key="training">Caregiver training</v-tab>
              </template>
              <v-tab key="logs">Logs</v-tab>
            </v-tabs>
          </v-toolbar>
          <v-tabs-items v-model="tabModel">
            <v-tab-item key="details">
              <v-card flat>
                <v-card-text v-if="sessionDetailed" class="pa-2">
                  <v-container fluid grid-list-sm pa-0>
                    <v-layout row wrap>
                      <v-flex xs6>
                        <v-layout row wrap>
                          <v-flex class="body-2 text-xs-right" xs4>Date:</v-flex>
                          <v-flex xs8>{{ sessionDetailed.sessionStart | moment("ddd") }}, {{ sessionDetailed.sessionStart | moment("ll") }}</v-flex>
                          <v-flex class="body-2 text-xs-right" xs4>Time IN:</v-flex>
                          <v-flex xs8>
                            <v-icon color="green" small>fa-sign-in-alt</v-icon>
                            {{ sessionDetailed.sessionStart | moment("LT") }}
                          </v-flex>
                          <v-flex class="body-2 text-xs-right" xs4>Time OUT:</v-flex>
                          <v-flex xs8>
                            <v-icon color="red" small>fa-sign-out-alt</v-icon>
                            {{ sessionDetailed.sessionEnd | moment("LT") }}
                          </v-flex>
                          <v-flex class="body-2 text-xs-right" xs4>Units:</v-flex>
                          <v-flex xs8>
                            <v-icon small>fa-star</v-icon>
                            {{ sessionDetailed.totalUnits.toLocaleString() }}
                            <v-icon small>fa-clock</v-icon>
                            {{ (sessionDetailed.totalUnits / 4).toLocaleString() }}
                          </v-flex>
                          <!-- <v-flex class="body-2 text-xs-right" xs4>Analyst:</v-flex>
                          <v-flex xs8>
                            <template v-for="u in clientAnalyst">
                              <span :key="'user' + u.userId">{{ u.firstname }} {{ u.lastname }}</span>
                              <br :key="'br' + u.userId" />
                            </template>
                          </v-flex> -->
                          <!-- <v-flex class="body-2 text-xs-right" xs4>Drive time (hrs):</v-flex>
                          <v-flex xs8>
                            <div v-if="!driveTimeEditVisible">
                              <v-icon small>fa-car</v-icon>
                              {{sessionDetailed.driveTime}}&nbsp;
                              <v-tooltip top>
                                <template #activator="data">
                                  <v-icon color="primary" style="cursor: pointer;" small v-on="data.on" @click="editDriveTime">fa-pen-alt</v-icon>
                                </template>
                                <span>Edit Drive time in hours</span>
                              </v-tooltip>
                            </div>
                            <div v-else>
                              <v-text-field :disabled="loadEditDriveTime" v-model="sessionDetailed.driveTime" class="pa-0" suffix="hrs" single-line hide-details append-outer-icon="fa-paper-plane" @click:append-outer="submitDriveTime" placeholder="Edit drive time" @keypress.enter.native="submitDriveTime"></v-text-field>
                            </div>
                          </v-flex> -->
                          <v-flex xs8 :offset-xs4="!sessionDetailed || !sessionDetailed.sign || !sessionDetailed.sign.sign">
                            <v-chip v-if="!sessionDetailed || !sessionDetailed.sign || !sessionDetailed.sign.sign" label disabled color="orange" text-color="white">
                              <v-avatar>
                                <v-icon>fa-signature</v-icon>
                              </v-avatar>
                              UNSIGNED
                            </v-chip>
                            <v-img max-width="300" :contain="true" max-height="100" v-else :src="!sessionDetailed || sessionDetailed.sign.sign" />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs6>
                        <v-layout row wrap>
                          <v-flex class="body-2 text-xs-right" xs4>Client:</v-flex>
                          <v-flex xs8>{{ sessionDetailed.clientFullname }} ({{ sessionDetailed.clientCode }})</v-flex>
                          <v-flex class="body-2 text-xs-right" xs4>Pos:</v-flex>
                          <v-flex xs8>
                            <div v-if="!posEditVisible">
                              <span class="text-no-wrap text-truncate">{{ sessionDetailed.pos }}</span> &nbsp;
                              <v-tooltip left>
                                <template #activator="data">
                                  <v-icon color="primary" style="cursor: pointer;" v-on="data.on" small @click="editPos">fa-pen-alt</v-icon>
                                </template>
                                <span>Edit POS</span>
                              </v-tooltip>
                            </div>
                            <v-select
                              v-if="posEditVisible"
                              :loading="loadingPosEdit"
                              :disabled="loading"
                              hide-details
                              single-line
                              class="pa-0 ma-0"
                              placeholder="Pos"
                              v-model="posToEdit"
                              :items="posEnum"
                              prepend-icon="fa-map-marker-alt"
                              @change="changeNewPos"
                            >
                              <template slot="selection" slot-scope="data">
                                <div class="input-group__selections__comma">
                                  {{ data.item.text }} &nbsp; <span class="grey--text text--darken-1">({{ data.item.value }})</span>
                                </div>
                              </template>
                              <template slot="item" slot-scope="data">
                                <template>
                                  <v-list-tile-avatar>
                                    <v-icon>fa-map-marker-alt</v-icon>
                                  </v-list-tile-avatar>
                                  <v-list-tile-content>
                                    <v-list-tile-title v-html="data.item.text"></v-list-tile-title>
                                    <v-list-tile-sub-title>Code: {{ data.item.value }}</v-list-tile-sub-title>
                                  </v-list-tile-content>
                                </template>
                              </template>
                            </v-select>
                          </v-flex>
                          <v-flex class="body-2 text-xs-right" xs4>Session type:</v-flex>
                          <v-flex xs8>{{ sessionDetailed.sessionType }}</v-flex>
                          <v-flex class="body-2 text-xs-right" xs4>Service:</v-flex>
                          <v-flex xs8>{{ sessionDetailed.hcpcs }} ({{ sessionDetailed.description }})</v-flex>
                          <v-flex class="body-2 text-xs-right" xs4>Provider:</v-flex>
                          <v-flex xs8>{{ sessionDetailed.user.firstname }} {{ sessionDetailed.user.lastname }}</v-flex>
                          <!-- <template v-if="clientAsistant.length > 0">
                            <v-flex class="body-2 text-xs-right" xs4>Assistant:</v-flex>
                            <v-flex xs8>
                              <template v-for="u in clientAsistant">
                                <span :key="'user' + u.userId">{{ u.firstname }} {{ u.lastname }}</span>
                                <br :key="'br' + u.userId" />
                              </template>
                            </v-flex>
                          </template>
                          <v-flex class="body-2 text-xs-right" xs4>Rbt:</v-flex>
                          <v-flex xs8>
                            <template v-for="u in clientRbt">
                              <span :key="'user' + u.userId">{{ u.firstname }} {{ u.lastname }}</span>
                              <br :key="'br' + u.userId" />
                            </template>
                          </v-flex> -->
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 v-if="sessionDetailed.sessionStatusCode == 2">
                        <v-alert :value="true" type="error">{{ session.sessionNote.rejectNotes }}</v-alert>
                      </v-flex>
                      <v-subheader>Notes</v-subheader>

                      <v-flex xs12 v-if="!editDisabled">
                        <vue-editor v-model="session.sessionNote.notes" @blur="quickSave()" @text-change="dirtyChange()" :editorOptions="config"></vue-editor>
                        <small class="right grey--text">{{ dirtyIndicator }}</small>
                      </v-flex>
                      <v-flex v-else>
                        <v-card flat>
                          <v-card-text v-html="session.sessionNote.notes"></v-card-text>
                        </v-card>
                      </v-flex>
                      <v-flex xs12>
                        <v-chip disabled :color="matchingLoading ? 'blue' : matchingAlert ? 'red' : 'green'" text-color="white">
                          <v-avatar>
                            <v-icon>{{ matchingLoading ? "fa-cog fa-spin" : "fa-check-circle" }}</v-icon>
                          </v-avatar>
                          {{ matchingLoading ? "Checking Similarity" : matchingAlert ? "Exceeds the percent" : "Checked" }}
                        </v-chip>
                      </v-flex>
                      <template v-if="!matchingLoading && matchingAlert">
                        <v-flex xs12>
                          Matching percentaje:
                          <v-chip label small color="red" text-color="white">
                            {{ (matching.percentaje * 100).toFixed(2) }}
                          </v-chip>
                        </v-flex>
                        <v-flex xs12>
                          <v-expansion-panel expand-icon="fa-caret-down">
                            <v-expansion-panel-content class="yellow lighten-4">
                              <template v-slot:header>
                                <div>Matching session: ID: {{ matching.sessionId }} Date: {{ matching.date | moment("utc", "MM/DD/YYYY") }}</div>
                              </template>
                              <v-card class="yellow lighten-5">
                                <v-card-text>
                                  {{ matching.notes }}
                                </v-card-text>
                              </v-card>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item v-if="session.sessionType == 1 || session.sessionType == 2" key="caregiver">
              <v-card flat>
                <v-card-text class="pa-2">
                  <v-container fluid grid-list-sm pa-0>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-select box hide-details :disabled="loading || editDisabled" label="Caregiver" v-model="session.sessionNote.caregiverId" :items="caregivers"></v-select>
                      </v-flex>
                      <v-flex xs12>
                        <v-textarea box hide-details :disabled="loading || editDisabled" label="Caregiver notes" auto-grow v-model="session.sessionNote.caregiverNote"></v-textarea>
                      </v-flex>
                      <v-flex xs12>
                        <v-autocomplete
                          box
                          hide-details
                          :disabled="loading || loadingAnalyst"
                          :items="analysts"
                          v-model="currentAnalyst"
                          label="Session analist"
                          item-text="fullname"
                          item-value="userId"
                          @change="sessionAnalystChange"
                          :loading="loadingAnalyst"
                        >
                          <template slot="item" slot-scope="{ item }">
                            <v-list-tile-avatar>
                              <v-icon>fa-user</v-icon>
                            </v-list-tile-avatar>
                            <v-list-tile-content>
                              <v-list-tile-title :class="{ 'grey--text text--lighten-1': !item.active }">{{ item.fullname }}</v-list-tile-title>
                              <v-list-tile-sub-title :class="{ 'grey--text text--lighten-1': !item.active }">{{ item.rolname }}</v-list-tile-sub-title>
                            </v-list-tile-content>
                          </template>
                        </v-autocomplete>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <template v-else-if="session.sessionType === 2">
              <v-tab-item key="training">
                <v-card flat>
                  <v-card-text class="pa-2">
                    <v-container fluid grid-list-sm pa-0>
                      <v-layout row wrap>
                        <v-flex xs12>
                          <v-switch
                            hide-details
                            color="primary"
                            label="Observation & feedback of recipients's interaction with parent/caregiver/others"
                            v-model="session.sessionNote.caregiverTrainingObservationFeedback"
                          ></v-switch>
                        </v-flex>
                        <v-flex xs12>
                          <v-switch hide-details color="primary" label="Parent/Caregiver training" v-model="session.sessionNote.caregiverTrainingParentCaregiverTraining"></v-switch>
                        </v-flex>
                        <v-flex xs12>
                          <v-switch hide-details color="primary" label="Competency check of caregiver" v-model="session.sessionNote.caregiverTrainingCompetencyCheck"></v-switch>
                        </v-flex>
                        <v-flex xs12>
                          <v-textarea box hide-details :disabled="loading" label="Other" auto-grow v-model="session.sessionNote.caregiverTrainingOther"></v-textarea>
                        </v-flex>
                        <v-flex xs12>
                          <v-textarea box hide-details :disabled="loading" label="Summary of training" auto-grow v-model="session.sessionNote.caregiverTrainingSummary"></v-textarea>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </template>
            <v-tab-item key="logs">
              <v-card flat>
                <v-card-text class="pa-2">
                  <v-container fluid grid-list-sm pa-0>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-timeline align-top dense v-if="sessionLogs">
                          <v-timeline-item :color="t.iconColor" :icon="t.icon" fill-dot small v-for="t in sessionLogs" :key="t.sessionLogId">
                            <v-layout pt-3>
                              <v-flex xs3>
                                {{ t.entry | moment("MM/DD/YYYY") }}
                                <br />
                                {{ t.entry | moment("LT") }}
                              </v-flex>
                              <v-flex xs9 sm7>
                                <strong :class="t.iconColor + '--text'">{{ t.title }}</strong>
                                <div class="caption">{{ t.description }}</div>
                                <v-divider></v-divider>
                                <div class="caption">
                                  <small>by {{ t.user.firstname }} {{ t.user.lastname }}</small>
                                </div>
                              </v-flex>
                              <v-flex sm2 class="hidden-xs-only">
                                <span class="caption">
                                  <small>{{ t.entry | moment("from", "now") }}</small>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-timeline-item>
                        </v-timeline>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <v-layout v-if="session.userId" row wrap px-3>
            <v-flex xs12>
              <small>Electronic Signature:</small>
            </v-flex>
            <v-flex xs6>
              <sign :userId="session.userId"></sign>
            </v-flex>
            <v-flex v-if="sessionAnalyst && sessionAnalyst.userId != session.userId" xs6>
              <sign :userId="sessionAnalyst.userId"></sign>
            </v-flex>
          </v-layout>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="loading" @click="close" flat>{{ editDisabled ? "CLOSE" : "CANCEL" }}</v-btn>
            <v-btn v-if="!editDisabled" :disabled="loading" :loading="loading" color="primary" @click="save(false)">Save</v-btn>
            <v-btn v-if="!editDisabled" :disabled="loading" :loading="loading" color="success" @click="save">Save and return</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <edit-time ref="editTimeModal" :sessionId="activeSessionId" :model="editTimeModal" @cancel="editTimeModal = false" @onSubmit="onSubmitEditTime"></edit-time>
  </v-container>
</template>

<script>
import clientApi from "@/services/api/ClientServices";
import userApi from "@/services/api/UserServices";
import sessionServicesApi from "@/services/api/SessionServices";
import editTime from "@/components/sessions/EditTime";
import masterTableApi from "@/services/api/MasterTablesServices";
import { VueEditor } from "vue2-editor";
import Sign from "@/components/shared/Sign";

export default {
  data() {
    return {
      // activeSessionId: null,
      tabModel: 0,
      loadingSession: false,
      loadingCaregivers: false,
      session: {
        sessionType: 0,
        sessionNote: {
          caregiverId: null,
          caregiverNote: null,
          notes: null
        },
        sessionSupervisionNote: {
          workWith: 0
        }
      },
      sessionDetailed: null,
      problemSelected: null,
      caregivers: [],
      editTimeModal: false,
      sessionLogs: [],
      posEnum: [],
      posToEdit: null,
      posEditVisible: false,
      lloadingPosEdit: false,
      driveTimeEditVisible: false,
      loadEditDriveTime: false,
      dirtyIndicator: null,
      // customToolBar: [
      //   ["bold", "italic", "underline", "strike"]
      // ],
      config: {
        modules: {
          toolbar: false
        }
      },
      clientRbt: [],
      clientAnalyst: [],
      clientAsistant: [],
      sessionAnalyst: null,
      analysts: [],
      loadingAnalyst: false,
      matching: {
        date: null,
        notes: null,
        percentaje: 0,
        sessionId: null
      },
      matchingLoading: false,
      matchingAlert: false
    };
  },

  components: {
    editTime,
    VueEditor,
    Sign
  },

  computed: {
    activeSessionId() {
      return this.$store.getters.activeSessionId;
    },
    activeClientId() {
      return this.$store.getters.activeClientId;
    },
    loading() {
      return this.loadingCaregivers || this.loadingSession;
    },
    editDisabled() {
      return !this.sessionDetailed || ((this.sessionDetailed.sessionStatusCode === 5 || this.sessionDetailed.sessionStatusCode === 6) && !this.isAdmin);
    },
    isBilled() {
      return !this.sessionDetailed || this.sessionDetailed.sessionStatusCode === 6;
    },
    user() {
      return this.$store.getters.user;
    },
    isAdmin() {
      return this.user.rol2 === "admin";
    },
    isAdminOrLead() {
      return this.user.rol2 === "admin" || this.user.rol2 === "analyst" || this.user.rol2 === "assistant";
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    }
  },

  async mounted() {
    if (!this.activeSessionId) this.close();
    this.posEnum = await masterTableApi.getPosCodes();
    await this.loadCaregivers();
    await this.loadAnalysts();
    await this.loadSessionData();
    await this.checkMatchingPercentaje();
  },

  methods: {
    async loadSessionData() {
      try {
        this.loadingSession = true;
        let sessionDetailed = await sessionServicesApi.getSessionDetailed(this.activeSessionId);
        let s1 = this.$moment(sessionDetailed.sessionStart).local();
        let s2 = this.$moment(sessionDetailed.sessionEnd).local();
        sessionDetailed.sessionStart = s1;
        sessionDetailed.sessionEnd = s2;
        this.sessionDetailed = sessionDetailed;
        this.sessionLogs = [];
        sessionDetailed.sessionLogs.forEach(s => {
          s.entry = this.$moment(s.entry).local();
          this.sessionLogs.push(s);
        });
        this.currentAnalyst = sessionDetailed.sessionAnalystId;
        this.session = await sessionServicesApi.getSession(this.activeSessionId);
        this.clientRbt = this.sessionDetailed.assignments.filter(f => f.rolId === 4);
        this.clientAnalyst = this.sessionDetailed.assignments.filter(f => f.rolId === 2);
        //        this.sessionAnalyst = this.clientAnalyst.shift();
        this.clientAsistant = this.sessionDetailed.assignments.filter(f => f.rolId === 3);
        const sessionExtraInfo = await sessionServicesApi.getSessionPrintExtraInfo(this.activeSessionId);
        this.sessionAnalyst = sessionExtraInfo.lead;
        this.dirtyIndicator = null;
      } catch (error) {
        this.$toast.error(error.message || error);
      } finally {
        this.loadingSession = false;
      }
    },

    async loadCaregivers() {
      try {
        this.loadingCaregivers = true;
        this.caregivers = await clientApi.getClientCaregivers(this.activeClientId);
      } catch (error) {
        this.$toast.error(error.message || error);
      } finally {
        this.loadingCaregivers = false;
      }
    },

    async loadAnalysts() {
      try {
        this.loadAnalysts = true;
        this.analysts = await userApi.getAnalistFromClient(this.activeClientId);
      } catch (error) {
        this.$toast.error(error.message || error);
      } finally {
        this.loadAnalysts = false;
      }
    },

    close() {
      if (this.isAdmin) {
        window.close();
        //this.$router.go(-1);
      }
      this.$router.push("/clients/sessions_details");
    },

    async quickSave() {
      try {
        if (this.dirtyIndicator === "* Modified") {
          this.loadingSession = true;
          await sessionServicesApi.editSessionNotes(this.session);
          this.dirtyIndicator = "Saved!";
          this.checkMatchingPercentaje();
        }
      } catch (error) {
        this.$toast.error(error.message || error);
      } finally {
        this.loadingSession = false;
      }
    },

    dirtyChange() {
      this.dirtyIndicator = "* Modified";
    },

    async save(exit = true) {
      if (this.session.sessionType === 3) {
        let work = 0;
        this.sessionSupervisionWorkWithArray.forEach(c => {
          work |= c;
        });
        this.session.sessionSupervisionNote.workWith = work;
      }

      try {
        this.loadingSession = true;
        await sessionServicesApi.editSessionNotes(this.session);
        if (exit) this.close();
        this.$toast.success("Session saved successful");
        this.loadSessionData();
        this.checkMatchingPercentaje();
      } catch (error) {
        this.$toast.error(error.message || error);
      } finally {
        this.loadingSession = false;
      }
    },

    async deleteSession() {
      this.$confirm("Do you want to delete this Session?").then(async res => {
        if (res) {
          try {
            if (!this.activeSessionId) return;
            await sessionServicesApi.deleteSession(this.activeSessionId);
            this.close();
          } catch (error) {
            this.$toast.error(error.message || error);
          }
        }
      });
    },

    rejectSession() {
      this.$prompt(null, { title: "Reject note", label: "Reason", textArea: true }).then(async desc => {
        if (desc) {
          if (!this.activeSessionId) return;
          await sessionServicesApi.rejectSession({
            sessionId: this.activeSessionId,
            RejectMessage: desc
          });
          this.close();
        }
      });
    },

    async send2Email() {
      let signPath = this.$router.resolve({
        name: "sign"
      }).href;
      let fullPath = `${window.location.origin}/${signPath}`;
      try {
        this.loadingSession = true;
        const response = await sessionServicesApi.sendUrlSign({ url: fullPath }, this.activeSessionId);
        this.loadSessionData();
        console.log(response);
        this.$toast.success("Email sent with code: " + response.statusCode);
      } catch (error) {
        this.$toast.error(error.message || error);
      } finally {
        this.loadingSession = false;
      }
    },

    async deleteSign() {
      try {
        this.loadingSession = true;
        await sessionServicesApi.deleteSign(this.activeSessionId);
        this.loadSessionData();
        this.$toast.success("Sign deleted successful");
      } catch (error) {
        this.$toast.error(error.message || error);
      } finally {
        this.loadingSession = false;
      }
    },

    async markAsChecked() {
      this.$confirm("Are you sure you want to check this session and notes?").then(async res => {
        if (res) {
          const model = {
            sessionId: this.activeSessionId,
            sessionStatus: 5 //checked
          };
          try {
            await this.save();
            await sessionServicesApi.changeSessionStatus(model);
            this.close();
          } catch (error) {
            this.$toast.error(error);
          }
        }
      });
    },

    editTime() {
      //this.$refs.editTimeModal.sessionId = this.activeSessionId;
      this.$refs.editTimeModal.orgTimeStart = this.sessionDetailed.sessionStart;
      this.$refs.editTimeModal.orgTimeEnd = this.sessionDetailed.sessionEnd;
      this.$refs.editTimeModal.timeStart = this.sessionDetailed.sessionStart.format("HH:mm");
      this.$refs.editTimeModal.timeEnd = this.sessionDetailed.sessionEnd.format("HH:mm");
      this.editTimeModal = true;
    },

    onSubmitEditTime() {
      this.editTimeModal = false;
      this.loadSessionData();
    },

    async reopenSession() {
      this.$confirm("Are you sure you want to reopen this session?").then(async res => {
        if (res) {
          const model = {
            sessionId: this.activeSessionId,
            sessionStatus: 4 //reopen
          };
          try {
            await sessionServicesApi.changeSessionStatus(model);
            this.close();
          } catch (error) {
            this.$toast.error(error);
          }
        }
      });
    },

    editPos() {
      this.posToEdit = this.sessionDetailed.posCode;
      this.posEditVisible = true;
    },

    async changeNewPos(pos) {
      this.loadingPosEdit = true;
      const model = {
        id: this.activeSessionId,
        value: pos
      };
      try {
        await sessionServicesApi.editSessionPos(model);
      } catch (error) {
        this.$toast.error("Error changing the POS value. Error: " + error);
      } finally {
        this.loadSessionData();
        this.posEditVisible = false;
        this.loadingPosEdit = false;
      }
    },

    editDriveTime() {
      //this.posToEdit = this.sessionDetailed.posCode;
      this.driveTimeEditVisible = true;
    },

    async submitDriveTime() {
      this.loadEditDriveTime = true;
      const model = {
        id: this.activeSessionId,
        value: this.sessionDetailed.driveTime
      };
      try {
        await sessionServicesApi.editSessionDriveTime(model);
      } catch (error) {
        this.$toast.error("Error changing the driving time value. Error: " + error);
      } finally {
        this.loadSessionData();
        this.driveTimeEditVisible = false;
        this.loadEditDriveTime = false;
      }
    },

    goToData() {
      this.$router.push("/session/session_collect_data");
    },

    async sessionAnalystChange(newAnalistId) {
      try {
        this.loadingAnalyst = true;
        const model = {
          sessionId: this.activeSessionId,
          analystId: newAnalistId
        };
        await sessionServicesApi.changeSessionAnalyst(model);
        this.$toast.success("Analyst changed successful, no need to save session.");
      } catch (error) {
        this.$toast.error(error.message || error);
      } finally {
        this.loadingAnalyst = false;
      }
    },

    async checkMatchingPercentaje() {
      try {
        //this.matching = null;
        this.matchingLoading = true;
        const res = await sessionServicesApi.checkMatchingPercentaje(this.activeSessionId);
        console.log(res);
        this.matching = res.percentaje == 0 ? { percentaje: null } : res;
        this.matchingAlert = this.matching.percentaje * 100 > 60;
        console.log(this.matching);
      } catch (error) {
        this.$toast.error(error.message || error);
      } finally {
        this.matchingLoading = false;
      }
    }
  }
};
</script>
